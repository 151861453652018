import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Listing from "../components/listing"
import contentParser from 'gatsby-wpgraphql-inline-images';

export default ({data}) => {
	
	const copertina = data.wpGraphQl.page.acf_wpgraphql_imgCopertina.imgCopertina.imageFile.childImageSharp.fluid 
	const pluginOptions = {
		wordPressUrl: `https://admin.borgosangiuliano.com/`,
		uploadsUrl: `https://admin.borgosangiuliano.com/wp/wp-content/uploads/`,
	}; 
	const content = data.wpGraphQl.page.content
	return (
	
	<Layout>

		<SEO
			title={data.wpGraphQl.page.seo.title}
			description={data.wpGraphQl.page.seo.metaDesc}
			img={copertina.src}
		/>

		<Img 
			className="immagine-copertina" 
			fluid={copertina} 
			alt-text={data.wpGraphQl.page.acf_wpgraphql_imgCopertina.imgCopertina.altText}/>

		<main>
			<div>

					<h1 dangerouslySetInnerHTML={{ __html: data.wpGraphQl.page.title }} />
					<div>{contentParser({ content }, pluginOptions)}</div> 

			</div>

			<Listing />

		</main>
	</Layout>
)}

export const pageQuery = graphql`
	query PagineWordpressGraphQl($id: ID!) {
		wpGraphQl {
			page(id: $id) {
				title
        seo {
          title
          metaDesc
        }
        acf_wpgraphql_imgCopertina {
          imgCopertina {
						altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1400) {
									...GatsbyImageSharpFluid_tracedSVG
                  src
                }
              }
            }
          }
        }
				content
				uri
			}
		}
  }

`